
/* eslint-disable */

import { ref, reactive, onMounted, provide } from "vue";
import { useRouter } from 'vue-router'
import CloudFun, { defineComponent } from "@cloudfun/core";

import QuickmodeBar from "@/components/quickmode-bar/Main.vue";
import HistoryModal from "@/components/quickmode-history-modal/Main.vue";

export default defineComponent({
  components: {
    QuickmodeBar,
    // HistoryModal,
  },
  setup() {
    const router = useRouter();
    return {
    };
  },
  methods: {},
});

<template>
  <div
    class="
      flex flex-col
      md:flex-row
      justify-between
      px-6
      lg:px-24
      py-10
      bg-grayscale-14
    "
  >
    <cf-select
      :MenuOptions="MenuOptions"
      placeholder="Select file"
      :required="false"
    />
    <cf-select
      :MenuOptions="ModelOptions"
      placeholder="Select model"
      :required="false"
    />

    <div class="w-full md:w-2/3 mr-5">
      <cf-button class="btn-secondary btn-base w-full">
        <i
          class="text-secondary text-xl icon-reminder mr-3"
          v-tooltip="{
            content:
              'The choosen model is trained <b>IMAGE</b> dataset. <br>Using <b>DICOM</b> format may affect the inference result.',
            html: true,
            placement: 'bottom'
          }"
        ></i>
        Get Result
      </cf-button>
    </div>

    <Popover class="relative flex justify-end">
      <PopoverButton
        class="
          flex
          justify-between
          items-center
          h-full
          md:w-full
          px-4
          mb-2
          md:mb-0
          border-0
          rounded
          bg-grayscale-11
          hover:bg-grayscale-10
          text-tertiary
          focus:ring-0
        "
        v-tooltip.bottom="'More options'"
      >
        <i class="text-secondary text-2xl icon-more"></i>
      </PopoverButton>
      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel
          class="
            shadow-normal
            absolute
            right-0
            top-16
            py-3
            z-10
            mt-3
            w-72
            max-w-sm
            transform
            lg:max-w-sm
            rounded
            border border-grayscale-10
            bg-grayscale-12
          "
        >
          <div class="border-b border-grayscale-10 pb-3 mb-3">
            <div class="flex items-center justify-between hover:bg-grayscale-9">
              <div class="group relative">
                <a href="#" class="flex items-center py-2 pl-10">
                  <div
                    class="
                      text-secondary
                      border-b border-dashed border-tertiary
                    "
                  >
                    Auto Start
                  </div>
                </a>
                <div
                  class="
                    hidden
                    group-hover:block
                    absolute
                    bg-secondary
                    left-1/2
                    -translate-x-1/2
                    -top-16
                    py-3
                    px-5
                    rounded-md
                    text-grayscale-14
                  "
                >
                  Automatically&nbsp;start&nbsp;inference <br />
                  when&nbsp;model&nbsp;or&nbsp;image&nbsp;is&nbsp;changed
                </div>
              </div>
              <div class="mr-5">
                <Switch
                  v-model="enabled"
                  :class="enabled ? 'border-primary' : ' border-tertiary'"
                  class="
                    relative
                    inline-flex
                    items-center
                    h-4
                    w-8
                    shrink-0
                    cursor-pointer
                    rounded-full
                    border-2
                    transition-colors
                    duration-200
                    ease-in-out
                    focus:outline-none
                    focus-visible:ring-2
                    focus-visible:ring-white
                    focus-visible:ring-opacity-75
                  "
                >
                  <span class="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    :class="
                      enabled
                        ? ' translate-x-4 bg-primary'
                        : ' translate-x-0 bg-tertiary'
                    "
                    class="
                      pointer-events-none
                      inline-block
                      h-5
                      w-5
                      transform
                      rounded-full
                      ring-0
                      transition-all
                      duration-200
                      ease-in-out
                    "
                  />
                </Switch>
              </div>
            </div>
          </div>
          <div class="">
            <div class="">
              <a
                href="#"
                class="flex items-center py-2 pl-10 hover:bg-grayscale-9"
              >
                <div class="text-secondary">Show history</div>
              </a>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>

  <div class="fixed left-1/2 -translate-x-1/2 top-1/4">
    <div
      class="
        bg-grayscale-12
        border-grayscale-10
        shadow-normal
        px-5
        py-3
        rounded
        text-secondary
      "
    >
      No&nbsp;history&nbsp;yet
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Switch,
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import CfSelect from "@/components/select/Main.vue";
import CfButton from "@/components/button/Main.vue";

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    Switch,
    CfSelect,
    CfButton,
    // Dialog,
    // DialogTitle,
    // DialogOverlay,
    // TransitionChild,
    // TransitionRoot,
  },
  setup() {
    const open = ref(true);

    return {
      open,
      MenuOptions: [
        {
          id: 1,
          href: "#",
          tooltipMsg: "",
          hastooltip: false,
          name: "import DICOM file",
          active: true,
        },
        {
          id: 2,
          href: "#",
          tooltipMsg: "Image file in JPG, JPEG, PNG, TIF or TIFF format",
          hastooltip: true,
          name: "import IMAGE file",
          active: false,
        },
      ],
      ModelOptions: [
        {
          id: 1,
          href: "#",
          tooltipMsg: "",
          hastooltip: false,
          name: "model a",
          active: false,
        },
        {
          id: 2,
          href: "#",
          tooltipMsg: "",
          hastooltip: false,
          name: "bone age",
          active: true,
        },
      ],
    };
  },
};
</script>

import { computed, defineComponent, PropType, ref } from "@cloudfun/core";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Switch,
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
export default defineComponent({
  components: {
    // Popover,
    // PopoverButton,
    // PopoverPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    // Switch,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "placeholder"
    },
    MenuOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    return {};
  },
});
